import { useEffect } from "react";
import { useQuerySubscription } from "react-datocms";
import { useLocation } from "wouter";

import { getSectionsQuery } from "api/graphQLQueries";

import { useNavigationStore } from "stores/navigationStore";

import {
  getSectionSlug,
  getSelectedSectionAndSelectedArticleFromLocation,
} from "utilities/navigationUtilities";

import Layout from "components/Layout";
import Spinner from "components/Spinner";

const DataWrapper = () => {
  const {
    setNavigation,
    setSelectedSectionAndArticle,
    allSectionsAndArticles,
    showDrafts,
  } = useNavigationStore();

  let [location] = useLocation();

  let { sectionSlug, articleSlug } = getSectionSlug(location);

  useEffect(() => {
    let { section, article } = getSelectedSectionAndSelectedArticleFromLocation(
      allSectionsAndArticles,
      location
    );

    setSelectedSectionAndArticle({
      selectedSection: section,
      selectedArticle: article,
    });
  }, [
    location,
    sectionSlug,
    articleSlug,
    setSelectedSectionAndArticle,
    allSectionsAndArticles,
  ]);

  // See https://github.com/datocms/react-datocms/blob/master/docs/live-real-time-updates.md for documentation for
  // useQuerySubscription
  const { data: allSectionsData, status } = useQuerySubscription({
    query: getSectionsQuery,
    token: "a74bc076638f0d233f6ed38d69aebe",
    preview: showDrafts,
  });

  useEffect(() => {
    setNavigation(allSectionsData?.allSections || []);
  }, [setNavigation, allSectionsData]);

  if (status === "connected") {
    return <Layout />;
  } else {
    return (
      <div className="w-screen h-screen flex flex-col items-center justify-center dark:bg-slate-900">
        <Spinner />
      </div>
    );
  }
};

export default DataWrapper;
