export const renderLiquidString = (string, authenticatedStaffMember) => {
  let { organization } = authenticatedStaffMember;

  let renderedString = (string || "")
    .replace(/{{\s?organization.name\s?}}/g, organization.name)
    .replace(/{{\s?organization.admin_url\s?}}/g, organization.admin_url)
    .replace(/{{\s?organization.subdomain\s?}}/g, organization.subdomain)
    .replace(
      /{{\s?staff_member.first_name\s?}}/g,
      authenticatedStaffMember.first_name,
    )
    .replace(
      /{{\s?staff_member.formatted_name\s?}}/g,
      authenticatedStaffMember.formatted_name,
    );

  return renderedString;
};
