import React from "react";
import clsx from "clsx";
import { Link } from "wouter";

import { generateTailwindGradientClassNames } from "utilities/colorUtilities";

const ResourcePreview = ({ resource }) => {
  return (
    <div className="rounded-lg mb-4 cursor-pointer bg-white px-4 py-4 shadow dark:border-slate-800 dark:bg-transparent dark:border-2 dark:hover:bg-slate-800">
      <Link key={resource.id} to={resource.href}>
        <div className="relative flex space-x-6 ">
          <div className="flex-shrink-0">
            {resource.featuredImage?.url ? (
              <img
                className="rounded-lg h-[100px] w-[100px]"
                src={resource.featuredImage.url}
                alt={resource.featuredImage.url}
              />
            ) : (
              <div
                className={clsx(
                  "rounded-lg h-[100px] w-[100px]",
                  generateTailwindGradientClassNames(resource.id)
                )}
              />
            )}
          </div>

          <div className="min-w-0 flex-1">
            <h3 className="mt-0 text-lg font-bold text-slate-700 dark:text-white">
              {resource.title}
            </h3>
            <p className="text-md text-slate-500 dark:text-slate-400">
              {resource.description}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ResourcePreview;
