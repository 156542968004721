import React, { Suspense } from "react";
import { Switch, Route } from "wouter";

import Breadcrumbs from "components/Breadcrumbs";
import ArticleWrapper from "components/ArticleWrapper";

const ContactPage = React.lazy(() => import("pages/ContactPage"));
const HomePage = React.lazy(() => import("pages/HomePage"));
const SystemStatusPage = React.lazy(() => import("pages/SystemStatusPage"));
const FeatureRequestsPage = React.lazy(() =>
  import("pages/FeatureRequestsPage")
);

const Page = () => {
  return (
    <div className="text-slate-700 mb-10">
      <Breadcrumbs />

      <Suspense fallback={<div>Loading</div>}>
        <Switch>
          {/** Static routes **/}
          <Route path="/" component={HomePage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/status" component={SystemStatusPage} />
          <Route path="/feature-requests" component={FeatureRequestsPage} />

          {/** Catchall route with no path defined, assume it's for an section/article.
          See https://github.com/molefrog/wouter#switch-
         **/}
          <Route component={ArticleWrapper} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Page;
