import React from "react";
import { useLocation } from "wouter";

import { useNavigationStore } from "stores/navigationStore";

import { getLastSlugFromPath } from "utilities/navigationUtilities";

import Resources from "components/Resources";

const SectionResources = () => {
  let [location] = useLocation();
  const { selectedSection } = useNavigationStore();

  const isDraft = selectedSection?.status === "draft";

  if (
    selectedSection &&
    getLastSlugFromPath(location) === selectedSection.slug
  ) {
    let sections = selectedSection.sections.filter(
      (resource) => resource.slug !== selectedSection.slug
    );
    let articles = selectedSection.articles.filter(
      (resource) => resource.slug !== selectedSection.slug
    );

    return (
      <>
        {sections.length ? (
          <Resources
            title={isDraft ? "Sections [Draft]" : "Sections"}
            resources={sections}
          />
        ) : null}
        {articles.length ? (
          <Resources
            title={isDraft ? "Articles [Draft]" : "Articles"}
            resources={articles}
          />
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default SectionResources;
