import { Link, useLocation } from "wouter";

import { Button } from "components/Button";

import { useNavigationStore } from "stores/navigationStore";

function ResourceLink({ label, resource, previous = false }) {
  return (
    <Link
      to={resource.href}
      tabIndex={-1}
      aria-hidden="true"
      className="text-base font-semibold text-zinc-900 transition hover:text-zinc-600 dark:text-white dark:hover:text-zinc-300"
    >
      <Button
        aria-label={`${label}: ${resource.title}`}
        variant="secondary"
        arrow={previous ? "left" : "right"}
      >
        {label}
      </Button>

      <div className="mt-2">{resource.title}</div>
    </Link>
  );
}

const PageNavigation = () => {
  const { navigation, allSectionsAndArticles } = useNavigationStore();

  let [location] = useLocation();

  if (!navigation[1]?.sections?.length) {
    return null;
  }

  let currentResourceIndex = allSectionsAndArticles.findIndex(
    (resource) => resource.href === location
  );

  if (currentResourceIndex === -1) {
    return null;
  }

  let previousResource = allSectionsAndArticles[currentResourceIndex - 1];
  let nextResource = allSectionsAndArticles[currentResourceIndex + 1];

  if (!previousResource && !nextResource) {
    return null;
  }

  return (
    <div className="flex">
      {previousResource && (
        <div className="flex flex-col items-start gap-3">
          <ResourceLink label="Previous" resource={previousResource} previous />
        </div>
      )}

      {nextResource && (
        <div className="ml-auto flex flex-col items-end gap-3">
          <ResourceLink label="Next" resource={nextResource} />
        </div>
      )}
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="mx-auto max-w-2xl space-y-10 pb-16 lg:max-w-5xl">
      <PageNavigation />

      <div className="flex flex-col items-center justify-between gap-5 border-t border-zinc-900/5 pt-8 dark:border-white/5 sm:flex-row">
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          &copy; Copyright {new Date().getFullYear()} Carebit Health Limited.{" "}
          <span style={{ marginLeft: "5px" }}>All rights reserved.</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
