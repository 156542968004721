import ResourcePreview from "components/ResourcePreview";

const Resources = ({ title, resources }) => (
  <div className="mt-8">
    <h2 className="text-lg font-bold my-4 dark:text-white">{title}</h2>
    <div className="grid grid-cols-1 gap-x-6 gap-y-2 lg:grid-cols-2 xl:gap-x-8">
      {resources.map((resource) => (
        <ResourcePreview key={resource.id} resource={resource} />
      ))}
    </div>
  </div>
);

export default Resources;
