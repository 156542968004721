import { flatten } from "lodash";

// Given a slug like 'services', find a full slug to use e.g. '/bookings/services' with a preference for
// keeping the user in the selectedSection if the selectedSection contains a record with the slug.
export const getHrefAttributeForLinkToRecord = (
  slug,
  selectedSection,
  allSectionsAndArticles
) => {
  let href = `/${slug}`;
  if (!selectedSection) {
    return href;
  }

  let matchedRecord =
    selectedSection.articles.find((article) => article.href.endsWith(href)) ||
    allSectionsAndArticles.find((record) => record.href.endsWith(href));

  return matchedRecord ? matchedRecord.href : href;
};

export const generateNavigation = (sections) => {
  return [
    {
      title: "Sections",
      sections: sections
        // Start with top level Sections only that do not have a parent Section and then traverse down
        .filter((section) => !section.parent)
        .map((section) => traverse("", section, sections)),
    },
    {
      title: "More",
      sections: [
        {
          id: 1,
          type: "article",
          title: "Feature requests",
          href: "/feature-requests",
          articles: [],
          children: [],
        },
        {
          id: 2,
          type: "article",
          title: "System status",
          href: "/status",
          articles: [],
          children: [],
        },
        {
          id: 3,
          type: "article",
          title: "Contact Support",
          href: "/contact",
          articles: [],
          children: [],
        },
      ],
    },
  ];
};

export const flattenNavigation = (navigation) => {
  return navigation.flatMap((navigationGroup) => {
    return navigationGroup.sections.flatMap((section) => {
      return flattenSection(section);
    });
  });
};

const flattenSection = (section) => {
  return flatten([
    section,
    ...(section.sections || []).map((s) => flattenSection(s)),
    ...section.articles,
  ]);
};

/**
 *
 * @param {String} parentHref - the href value for the parent Resource
 * @param {Object} resource  - the Section or Article model from DatoCMS
 * @returns {Object}
 */
const traverse = (parentHref, resource, allSections) => {
  let href = `${parentHref}/${resource.slug}`;

  return {
    id: resource.id,
    slug: resource.slug,
    href: href,
    _modelApiKey: resource._modelApiKey, // ['section', 'article']
    status: resource._status,
    parentSection: resource.parent,
    title: resource.title,
    description: resource.description,
    featuredImage: resource.featuredImage,
    furtherReadingLinks: (resource.furtherReadingLinks || []).map(
      ({ id, slug, title, description }) => ({
        id,
        slug,
        title,
        description,
      })
    ),
    content: resource.content,
    sections: mapSections(href, resource.children || [], allSections),
    articles: mapArticles(
      href,
      resource._allReferencingArticles || [],
      allSections
    ),
  };
};

const mapSections = (href, sections, allSections) => {
  return sections.map((childSection) => {
    let matchedSection = allSections.find((s) => s.id === childSection.id);

    return traverse(href, matchedSection, allSections);
  });
};

const mapArticles = (href, articles, allSections) => {
  return articles.map((article) => {
    return traverse(href, article, allSections);
  });
};

export const getSelectedResources = (allResources, slug) => {
  let matchedItems = allResources.filter((item) => item.href?.includes(slug));
  let selectedSection = matchedItems.find(
    (item) => item._modelApiKey === "section"
  );
  let selectedArticle = matchedItems.find(
    (item) =>
      item._modelApiKey === "article" && slug === getLastSlugFromPath(item.href)
  );

  return { selectedSection, selectedArticle };
};

/**
 *
 * @param {String} pathname  e.g. '/apps-and-integrations/xero'
 * @returns {Array} e.g. ['apps-and-integrations', 'xero']
 */
export const getSlugsArrayFromPathname = (pathname) => {
  if (!pathname) {
    return [];
  } else {
    return pathname.split("/").filter((item) => item.length);
  }
};

export const getSelectedSectionAndSelectedArticleFromLocation = (
  allSectionsAndArticles,
  pathname
) => {
  let lastSlug = getLastSlugFromPath(pathname);

  let article = allSectionsAndArticles.find(
    (resource) =>
      resource._modelApiKey === "article" && resource.slug === lastSlug
  );

  let section = allSectionsAndArticles.find(
    (resource) =>
      resource._modelApiKey === "section" && resource.slug === lastSlug
  );

  if (!section) {
    // If there is no matching Section for the last slug, then the slug is for an Article.
    // Go one back from the article slug to find the parent section.
    let slugsArray = getSlugsArrayFromPathname(pathname);
    let articleIndex = slugsArray.findIndex((slug) => slug === lastSlug);
    let sectionSlug = slugsArray[articleIndex - 1];
    section = allSectionsAndArticles.find(
      (resource) =>
        resource._modelApiKey === "section" && resource.slug === sectionSlug
    );
  }

  return { section, article };
};

export const getSectionSlug = (pathname) => {
  let levels = pathname.split("/").filter((item) => item.length);

  if (levels.length === 3) {
    // e.g. 'automation/bookings-recalls-reminders/recalls'
    return { sectionSlug: levels[1], articleSlug: levels[2] };
  } else {
    // e.g. 'automation/bookings-recalls-reminders'
    return { sectionSlug: levels[0], articleSlug: levels[1] };
  }
};

/**
 *
 * @param {String} pathname  e.g. '/apps-and-integrations/xero'
 * @returns {String} e.g.'apps-and-integrations'
 */
export const getTopLevelSlugFromPath = (pathname) =>
  getSlugsArrayFromPathname(pathname)[0];

/**
 *
 * @param {String} pathname  e.g. '/apps-and-integrations/xero'
 * @returns {String} e.g.'xero'
 */
export const getLastSlugFromPath = (pathname) => {
  return pathname?.split("/")?.pop();
};

export const convertHeadingTitleToId = (string) => {
  if (string) {
    return string.toLowerCase().replace(/ /g, "-");
  } else {
    return "";
  }
};
