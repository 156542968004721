// Useful reading:
// https://www.datocms.com/docs/content-delivery-api/modular-content-fields
// _allReferencingArticles field - https://www.datocms.com/docs/content-delivery-api/inverse-relationships
// https://www.datocms.com/docs/content-delivery-api/meta-fields
// https://www.datocms.com/docs/content-delivery-api/structured-text-fields

export const getArticlesQuery = `
  query {
    allArticles(first: 100) {
      id
      _modelApiKey
      title
      slug
      content

      section {
        id
        _modelApiKey
        title
        description
        slug
      }
    }
  }
`;

export const getSectionsQuery = `
    {
      allSections(first: 100) {
        id
        _modelApiKey
        _status
        title
        description
        slug
        featuredImage {
          url
        }

        parent {
          id
          _modelApiKey
          _status
          title
          description
          slug
          featuredImage {
            url
          }
        }

        children {
          id
          _modelApiKey
          _status
          title
          description
          slug
          featuredImage {
            url
          }
        }

        _allReferencingArticles {
          id
          _modelApiKey
          _status
          title
          description
          slug
          featuredImage {
            url
          }
          furtherReadingLinks {
            ... on ArticleRecord {
              __typename
              id
              slug
              title
              description
            }
            ... on SectionRecord {
              __typename
              id
              slug
              title
              description
            }
          }
          content {
            ... on InternalNoteRichTextBlockRecord {
              id
              _modelApiKey
              richText {
                value
              }
            }
            ... on RichTextBlockRecord {
              id
              _modelApiKey
              richText {
                value
                links {
                  __typename
                  ... on ArticleRecord {
                    id
                    slug
                  }
                }
              }
            }
            ... on InfoBlockRecord {
              id
              _modelApiKey
              title
              richText {
                value
                links {
                  __typename
                  ... on ArticleRecord {
                    id
                    slug
                  }
                }
              }
            }
            ... on WarningBlockRecord {
              id
              _modelApiKey
              title
              richText {
                value
                links {
                  __typename
                  ... on ArticleRecord {
                    id
                    slug
                  }
                }
              }
            }
            ... on ImageBlockRecord {
              id
              _modelApiKey
              image {
                url
              }
              caption
            }
            ... on VideoBlockRecord {
              id
              _modelApiKey
              video {
                url
              }
              caption
            }
         }
      }
    }
  }
`;
