import { HomeIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link, useLocation } from "wouter";
import clsx from "clsx";
import { capitalize } from "lodash";

import { getSlugsArrayFromPathname } from "utilities/navigationUtilities";

import { useNavigationStore } from "stores/navigationStore";

const Breadcrumbs = () => {
  let [location] = useLocation();
  const { allSectionsAndArticles } = useNavigationStore();

  const breadcrumbItems = getSlugsArrayFromPathname(location).map(
    (slug, index) => {
      let matchedSectionOrArticle = allSectionsAndArticles.find(
        (item) => item.href === `/${slug}`
      ) ||
        allSectionsAndArticles.find(
          (item) => item.href.split("/").pop() === slug
        ) || { title: capitalize(slug), href: `/${slug}` }; // e.g. for static pages like '/contact'

      return {
        name: matchedSectionOrArticle?.title?.replace("-", " "),
        href: matchedSectionOrArticle?.href,
        isCurrentPath: matchedSectionOrArticle?.href === location.pathname,
        isDraft: matchedSectionOrArticle?.status === "draft",
      };
    }
  );

  return (
    <nav className="flex mb-6" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-2">
        <li key="Home">
          <Link
            to="/"
            className="text-gray-400 hover:text-gray-500 dark:text-sky-500"
          >
            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </li>

        {breadcrumbItems.map((breadcrumbItem, index) => {
          let isLastItemInArray = index === breadcrumbItems.length - 1;
          return (
            <li key={index}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to={breadcrumbItem.href || ""}
                  className={clsx(
                    "ml-2 text-md",
                    isLastItemInArray
                      ? "font-medium text-gray-500 dark:text-zinc-400"
                      : "u-colorPurple font-bold dark:text-sky-500"
                  )}
                  aria-current={
                    breadcrumbItem.isCurrentPath ? "page" : undefined
                  }
                >
                  {breadcrumbItem.name}
                  {breadcrumbItem.isDraft && " [Draft]"}
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
