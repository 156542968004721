import { useEffect, useState } from "react";
import { Link } from "wouter";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, Hits } from "react-instantsearch-hooks-web";

import Modal from "./Modal";

const searchClient = algoliasearch(
  "SOGDXM0NCV",
  "1e1be2abdcf1625df6482fb1073e21e6"
);

const SearchIcon = (props) => (
  <svg aria-hidden="true" viewBox="0 0 20 20" {...props}>
    <path d="M16.293 17.707a1 1 0 0 0 1.414-1.414l-1.414 1.414ZM9 14a5 5 0 0 1-5-5H2a7 7 0 0 0 7 7v-2ZM4 9a5 5 0 0 1 5-5V2a7 7 0 0 0-7 7h2Zm5-5a5 5 0 0 1 5 5h2a7 7 0 0 0-7-7v2Zm8.707 12.293-3.757-3.757-1.414 1.414 3.757 3.757 1.414-1.414ZM14 9a4.98 4.98 0 0 1-1.464 3.536l1.414 1.414A6.98 6.98 0 0 0 16 9h-2Zm-1.464 3.536A4.98 4.98 0 0 1 9 14v2a6.98 6.98 0 0 0 4.95-2.05l-1.414-1.414Z" />
  </svg>
);

export const Search = () => {
  let [showModal, setShowModal] = useState(false);
  let [modifierKey] = useState(
    /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "⌘" : "Ctrl "
  );

  const onKeyDownSearchKeyboardShortcut = (e) => {
    let kKeyCode = 75;
    // e.metaKey is "⌘" on Mac, or Windows logo/Ctrl key on Windows
    if ((e.metaKey || e.ctrlKey) && e.keyCode === kKeyCode) {
      setShowModal(!showModal);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDownSearchKeyboardShortcut);

    return () => {
      document.removeEventListener("keydown", onKeyDownSearchKeyboardShortcut);
    };
  });

  const Hit = ({ hit }) => {
    return (
      <div className="rounded shadow-sm border mb-2 p-3 transition hover:bg-slate-200">
        <Link
          to={hit.href}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <h3 className="text-lg font-bold text-slate-700">{hit.title}</h3>
          <p className="text-slate-500">{hit.description}</p>
        </Link>
      </div>
    );
  };

  return (
    <>
      <button
        type="button"
        className="group flex h-6 w-6 items-center justify-center sm:justify-start md:h-auto md:w-80 md:flex-none md:rounded-lg md:py-2.5 md:pl-4 md:pr-3.5 md:text-sm md:ring-1 md:ring-slate-200 md:hover:ring-slate-300 dark:md:bg-slate-800/75 dark:md:ring-inset dark:md:ring-white/5 dark:md:hover:bg-slate-700/40 dark:md:hover:ring-slate-500 lg:w-96"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <SearchIcon className="h-5 w-5 flex-none fill-slate-400 group-hover:fill-slate-500 dark:fill-slate-500 md:group-hover:fill-slate-400" />
        <span className="sr-only text-md md:not-sr-only md:ml-2 md:text-slate-500 md:dark:text-slate-400">
          Search
        </span>

        {modifierKey && (
          <kbd className="ml-auto hidden font-medium text-slate-400 dark:text-slate-500 md:block">
            <kbd className="font-sans">{modifierKey}</kbd>
            <kbd className="font-sans">K</kbd>
          </kbd>
        )}
      </button>

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      >
        {/* https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/#add-a-search-box */}
        <InstantSearch
          searchClient={searchClient}
          indexName="carebit_admin_support"
        >
          {/* https://www.algolia.com/doc/api-reference/widgets/search-box/react-hooks/#examples */}
          <SearchBox
            placeholder="Search documentation"
            autoFocus={true}
            classNames={{
              input:
                "outline-offset-0 outline-indigo-500 h-auto w-full rounded-lg p-4 border text-slate-700",
              reset: "hidden",
              submit: "hidden",
              submitIcon: "hidden",
              resetIcon: "hidden",
            }}
          />

          <div className="mt-6 h-96 overflow-auto">
            <h4 className="uppercase text-xs tracking-wider font-bold text-slate-400 mb-2">
              Results
            </h4>

            <Hits classNames={{}} hitComponent={Hit} />
          </div>
        </InstantSearch>
      </Modal>
    </>
  );
};
