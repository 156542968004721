import React, { useContext } from "react";
import { Link } from "wouter";
import { renderNodeRule, StructuredText } from "react-datocms";
import { isHeading, isLink } from "datocms-structured-text-utils";
import { render as toPlainText } from "datocms-structured-text-to-plain-text";
import { LinkIcon } from "@heroicons/react/20/solid";

import { AuthenticationContext } from "App";

import {
  convertHeadingTitleToId,
  getHrefAttributeForLinkToRecord,
} from "utilities/navigationUtilities";
import { renderLiquidString } from "utilities/renderingUtilities";

const DatoCMSRichTextRenderer = ({
  contentItem,
  selectedSection,
  allSectionsAndArticles,
}) => {
  const { authenticatedStaffMember } = useContext(AuthenticationContext);

  return (
    <StructuredText
      data={contentItem.richText}
      renderText={(text) => {
        if (text.includes("{{")) {
          return renderLiquidString(text, authenticatedStaffMember);
        } else {
          return text;
        }
      }}
      renderLinkToRecord={({ record, children, transformedMeta }) => {
        // These are internal links to other DatoCMS records.
        const url = getHrefAttributeForLinkToRecord(
          record.slug,
          selectedSection,
          allSectionsAndArticles
        );

        return (
          <Link
            {...transformedMeta}
            className="u-colorPurple font-bold"
            to={url}
          >
            {children}
          </Link>
        );
      }}
      customNodeRules={[
        // Useful links:
        // https://github.com/datocms/structured-text/tree/main/packages/utils#typescript-type-guards
        // https://github.com/datocms/structured-text/blob/main/packages/generic-html-renderer/src/index.ts#L230
        // https://github.com/datocms/react-datocms/blob/master/docs/structured-text.md#custom-renderers-for-inline-records-blocks-and-links

        renderNodeRule(isLink, ({ adapter: { renderNode }, node, key }) => {
          let renderedUrl = renderLiquidString(
            node.url,
            authenticatedStaffMember
          );
          let renderedText = renderLiquidString(
            toPlainText(node),
            authenticatedStaffMember
          );

          // Respect links that are supposed to open in a new window
          const attributes = { href: renderedUrl, key };
          if (node.meta) {
            node.meta.forEach((item) => {
              attributes[item.id] = item.value;
            });
          }

          if (attributes.target === "_blank") {
            attributes.rel = "noreferrer";
          }

          return renderNode("a", attributes, renderedText);
        }),

        // Add HTML anchors to heading levels for in-page navigation
        renderNodeRule(isHeading, ({ node, children, key }) => {
          const HeadingTag = `h${node.level}`;
          const id = convertHeadingTitleToId(toPlainText(node));

          let className = "lg";
          switch (node.level) {
            case "1":
              className = "3xl";
              break;
            case "2":
              className = "2xl";
              break;
            case "3":
              className = "xl";
              break;
            default:
              className = "xl";
              break;
          }

          return (
            <HeadingTag className={`text-${className}`} key={key}>
              <a
                id={id}
                href={`#${id}`}
                onClick={() => {
                  window.navigator.clipboard.writeText(
                    `${window.location.origin}${window.location.pathname}#${id}`
                  );
                }}
              >
                {children}
                <span className="inline-block">
                  <LinkIcon
                    className="h-5 w-5 ml-2 relative top-0.5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </a>
            </HeadingTag>
          );
        }),
      ]}
    />
  );
};

export default DatoCMSRichTextRenderer;
