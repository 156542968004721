import { useContext } from "react";
import { Link, useLocation } from "wouter";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

import { Tag } from "components/Tag";

import { remToPx } from "utilities/sizingUtilities";
import { getLastSlugFromPath } from "utilities/navigationUtilities";

import { useNavigationStore } from "stores/navigationStore";
import Toggle from "components/Toggle";

import { AuthenticationContext } from "App";

function TopLevelNavItem({ href, children }) {
  return (
    <li className="md:hidden">
      <Link
        to={href}
        className="block py-1 text-md text-zinc-600 transition hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-white"
      >
        {children}
      </Link>
    </li>
  );
}

function NavLink({ to, tag, active, children }) {
  // Count the number of '/' characters in the path to determine the levels.
  let levelsCount = (to.match(/\//g) || []).length;
  let padding = "pl-3";
  switch (levelsCount) {
    case 2:
      padding = "pl-5";
      break;
    case 3:
      padding = "pl-7";
      break;
    default:
      break;
  }

  return (
    <Link
      to={to}
      aria-current={active ? "page" : undefined}
      className={clsx(
        "flex justify-between gap-2 py-1 pr-3 text-md transition",
        padding,
        active
          ? "text-zinc-700 dark:text-white"
          : "text-zinc-600 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-white"
      )}
    >
      <span className="truncate">{children}</span>
      {tag && (
        <Tag variant="small" color="zinc">
          {tag}
        </Tag>
      )}
    </Link>
  );
}

const ActiveSectionMarker = ({ navigationGroup }) => {
  const { selectedSection } = useNavigationStore();

  let itemHeight = remToPx(2);
  let offset = remToPx(0.25);
  let activePageIndex = navigationGroup.sections.findIndex(
    (section) =>
      section.id === selectedSection?.id ||
      section.id === selectedSection?.parentSection?.id
  );
  let top = offset + activePageIndex * itemHeight;

  return (
    <motion.div
      layout
      className="absolute left-2 h-6 w-px bg-emerald-500"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { delay: 0.2 } }}
      exit={{ opacity: 0 }}
      style={{ top }}
    />
  );
};

const NavSection = ({ section }) => {
  const { navigation } = useNavigationStore();
  const [location] = useLocation();

  let isActive = location.includes(section.href);

  return (
    <motion.li key={section.href} layout="position" className="relative">
      <NavLink to={section.href} active={isActive}>
        <span className={isActive ? "font-bold" : ""}>
          {section.title}
          {section.status === "draft" && " [Draft]"}
        </span>
      </NavLink>

      <AnimatePresence mode="popLayout" initial={false}>
        {isActive && navigation.length > 0 && (
          <motion.ul
            role="list"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.1 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15 },
            }}
          >
            {[section, ...(section.sections || [])].map((s) => (
              <NavLinks key={s.id} section={s} />
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </motion.li>
  );
};

const NavLinks = ({ section }) => {
  const [location] = useLocation();
  const { selectedSection } = useNavigationStore();

  if (location.includes(section.href)) {
    const items = [...(section.sections || []), ...section.articles];

    return items
      .filter((resource) => {
        // Filter out the overview page from the list of Articles
        return (
          resource._modelApiKey === "section" ||
          getLastSlugFromPath(resource.href) !==
            getLastSlugFromPath(selectedSection?.href)
        );
      })
      .map((resource) => {
        return (
          <motion.li
            key={resource.id}
            className={location.includes(resource.href) ? "font-bold" : ""}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.1 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15 },
            }}
          >
            <NavLink to={resource.href} tag={resource.tag}>
              {resource.title}
              {resource.status === "draft" && " [Draft]"}
            </NavLink>
          </motion.li>
        );
      });
  } else {
    return null;
  }
};

const NavigationGroup = ({ navigationGroup, className }) => {
  const { selectedSection } = useNavigationStore();

  let isActiveSection = !!navigationGroup.sections.find(
    (section) =>
      section.id === selectedSection?.id ||
      section.id === selectedSection?.parentSection?.id
  );

  return (
    <li className={clsx("relative mt-6", className)}>
      <motion.h2
        layout="position"
        className="text-md font-semibold text-zinc-700 dark:text-white"
      >
        {navigationGroup.title}
      </motion.h2>

      <div className="relative mt-3 pl-2">
        <motion.div
          layout
          className="absolute inset-y-0 left-2 w-px bg-zinc-700/10 dark:bg-white/5"
        />

        <AnimatePresence initial={false}>
          {isActiveSection && (
            <ActiveSectionMarker navigationGroup={navigationGroup} />
          )}
        </AnimatePresence>

        <ul>
          {navigationGroup.sections.map((section) => {
            return <NavSection key={section.id} section={section} />;
          })}
        </ul>
      </div>
    </li>
  );
};

const Navigation = ({ className }) => {
  const { navigation, setShowDrafts, showDrafts } = useNavigationStore();
  const { authenticatedStaffMember } = useContext(AuthenticationContext);

  function setDraftsVisible(value) {
    setShowDrafts(value);
  }

  return (
    <nav className={className}>
      <ul>
        <TopLevelNavItem href="/contact">Contact Support</TopLevelNavItem>

        {navigation.map((navigationGroup, index) => {
          return (
            <NavigationGroup
              key={navigationGroup.title}
              navigationGroup={navigationGroup}
              className={index === 0 && "md:mt-0"}
            />
          );
        })}
        {authenticatedStaffMember.email.endsWith("carebit.co") && (
          <div className="mt-10">
            <Toggle
              defaultChecked={showDrafts}
              label="Show drafts"
              onChange={setDraftsVisible}
            />
          </div>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
