import { Fragment } from "react";
import { Link } from "wouter";
import { motion } from "framer-motion";

import Logo from "images/logo.jpg";

import Header from "components/Header";
import Navigation from "components/Navigation";
import Page from "components/Page";
import Footer from "components/Footer";

const Layout = () => {
  return (
    <Fragment>
      <div className="lg:ml-72 xl:ml-80">
        <motion.header
          layoutScroll
          className="contents lg:pointer-events-none lg:fixed lg:inset-0 lg:z-40 lg:flex"
        >
          <div className="contents lg:pointer-events-auto lg:block lg:w-72 lg:overflow-y-auto lg:border-r lg:border-zinc-900/10 lg:px-6 lg:pb-8 lg:pt-4 lg:dark:border-white/10 xl:w-80">
            <div className="hidden lg:flex">
              <Link to="/" aria-label="Home">
                <img src={Logo} alt="Carebit logo" style={{ width: "120px" }} />
              </Link>
            </div>

            <Header />

            <Navigation className="hidden lg:mt-10 lg:block" />
          </div>
        </motion.header>

        <div className="relative px-4 pt-14 sm:px-6 lg:px-8">
          <main className="py-6">
            <Page />
          </main>

          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
