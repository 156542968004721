import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";

const colorStyles = {
  info: {
    borderColor: "border-blue-400",
    background: "bg-blue-50",
    icon: "text-blue-400",
    text: "text-blue-900",
  },
  warning: {
    borderColor: "border-yellow-400",
    background: "bg-yellow-50",
    icon: "text-yellow-400",
    text: "text-yellow-900",
  },
};

const Banner = ({ status, title, children }) => {
  let colorsForStatus = colorStyles[status];

  return (
    <div
      className={`Banner px-3 py-2 mt-6 mb-6 text-md rounded border-l-4 ${colorsForStatus.borderColor} ${colorsForStatus.background}`}
    >
      <div className="flex">
        <div className="flex-shrink-0 relative top-2.5">
          {status === "info" ? (
            <InformationCircleIcon
              className={`h-5 w-5 ${colorsForStatus.icon}`}
              aria-hidden="true"
            />
          ) : (
            <ExclamationTriangleIcon
              className={`h-5 w-5 ${colorsForStatus.icon}`}
              aria-hidden="true"
            />
          )}
        </div>

        <div className="ml-2">
          <h4 className={`${colorsForStatus.text} font-bold`}>{title}</h4>
          <div className={`${colorsForStatus.text}`}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
