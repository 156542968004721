// From https://hypercolor.dev
export const tailwindGradientMapping = {
  0: "bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500",
  1: "bg-gradient-to-br from-green-300 via-blue-500 to-purple-600",
  2: "bg-gradient-to-br from-pink-300 via-purple-300 to-indigo-400",
  3: "bg-gradient-to-br from-indigo-200 via-red-200 to-yellow-100",
  4: "bg-gradient-to-br from-green-200 via-green-300 to-blue-500",
  5: "bg-gradient-to-br from-red-500 to-red-800",
  6: "bg-gradient-to-br from-green-200 to-green-500",
  7: "bg-gradient-to-br from-gray-900 via-purple-900 to-violet-600",
  8: "bg-gradient-to-br from-blue-700 via-blue-800 to-gray-900",
  9: "bg-gradient-to-tl from-sky-400 to-indigo-900",
  10: "bg-gradient-to-br from-green-300 to-purple-400",
  11: "bg-gradient-to-br from-yellow-200 via-pink-200 to-pink-400",
  12: "bg-gradient-to-br from-green-500 to-green-700",
  13: "bg-gradient-to-br from-rose-400 via-fuchsia-500 to-indigo-500",
  14: "bg-gradient-to-br from-rose-100 to-teal-100",
  15: "bg-gradient-to-br from-sky-400 to-sky-200",
  16: "bg-gradient-to-br from-orange-500 to-yellow-300",
  17: "bg-gradient-to-br from-rose-400 to-orange-300",
  18: "bg-gradient-to-br from-teal-200 to-lime-200",
  19: "bg-gradient-to-br from-blue-400 to-emerald-400",
  20: "bg-gradient-to-br from-sky-400 to-blue-500",
};

export const generateTailwindGradientClassNames = (resourceId) => {
  // Generate a number between 0 and 20 based on the last 2 digits of the resource ID.
  // This number will be used to look up a specific gradient to use for color-coding this resource.
  // Because it's based on the ID, it's consistent across page reloads which wouldn't be the case with a random number.
  const numbers = resourceId.match(/\d{1,2}/g); // Extract the digits from the resource ID
  let resourceID2Digits = "99";
  if (numbers && numbers.length >= 2) {
    resourceID2Digits = numbers.slice(-2); // Select the last 2 digits
  }

  // Divide by 5 and round to get a number between 0 and 20
  const colorKey = Math.round(parseInt(resourceID2Digits) / 5).toString();

  return tailwindGradientMapping[colorKey];
};
