import Logo from "images/logo.jpg";

const UnauthenticatedPage = () => {
  return (
    <div className="h-96 w-full flex align-center justify-center">
      <div className="text-center mt-10 p-10">
        <img
          src={Logo}
          alt="Carebit logo"
          className="mx-auto mb-10"
          style={{ width: "200px" }}
        />

        <h1 className="font-bold text-2xl text-slate-700 dark:text-zinc-400">
          Unfortunately we weren’t able to automatically log you into the
          Support section.
        </h1>

        <p className="text-slate-500 mt-2 dark:text-zinc-400">
          To access the Support section, please log into Carebit and click the
          Support link again.
        </p>
      </div>
    </div>
  );
};

export default UnauthenticatedPage;
