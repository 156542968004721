import { createContext, useEffect } from "react";
import Cookies from "universal-cookie";
import qs from "qs";
import { useLocation } from "wouter";

import DataWrapper from "components/DataWrapper";
import UnauthenticatedPage from "pages/UnauthenticatedPage";

import {
  AUTHENTICATION_COOKIE_NAME,
  mockAuthenticatedStaffMember,
  setMockAuthenticationCookie,
} from "utilities/authenticationUtilities";

export const AuthenticationContext = createContext();

const App = () => {
  const [location, setLocation] = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, [location]);

  const cookies = new Cookies();

  let authenticatedStaffMemberStringFromParams = qs.parse(
    window.location.search,
    {
      ignoreQueryPrefix: true,
    }
  ).authenticatedStaffMember;

  let authenticatedStaffMemberObject;

  if (
    !authenticatedStaffMemberObject &&
    window.location.hostname === "localhost"
  ) {
    authenticatedStaffMemberObject = mockAuthenticatedStaffMember;
    setMockAuthenticationCookie();
  }

  // If authentication fails, we want to store the page being accessed to redirect the user to their
  // originally-requested page when they do successfully authenticate.
  cookies.set("carebitLastPageAccessed", window.location.pathname, {
    path: "/",
    maxAge: 60 * 60 * 24 * 7, // 1 week in seconds
  });

  if (authenticatedStaffMemberStringFromParams) {
    // Store in cookies so we can easily reauthenticate in future.
    cookies.set(
      AUTHENTICATION_COOKIE_NAME,
      authenticatedStaffMemberStringFromParams,
      {
        path: "/",
        maxAge: 60 * 60 * 24 * 7, // 1 week in seconds
      }
    );

    setLocation(cookies.get("carebitLastPageAccessed") || "/");
    cookies.remove("carebitLastPageAccessed", {
      path: "/",
    });
    authenticatedStaffMemberObject = JSON.parse(
      authenticatedStaffMemberStringFromParams
    );
  } else if (cookies.get(AUTHENTICATION_COOKIE_NAME)) {
    // The cookie may have been set from the params as above, or by an authentication pixel at
    // https://support-auth.carebit.co/auth.gif?authenticatedStaffMember=....
    authenticatedStaffMemberObject = cookies.get(AUTHENTICATION_COOKIE_NAME);
  } else {
    return <UnauthenticatedPage />;
  }

  try {
    return (
      <AuthenticationContext.Provider
        value={{
          authenticatedStaffMember: authenticatedStaffMemberObject,
        }}
      >
        <DataWrapper />
      </AuthenticationContext.Provider>
    );
  } catch (error) {
    return <UnauthenticatedPage />;
  }
};

export default App;
