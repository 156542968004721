import React from "react";
import clsx from "clsx";
import { Link } from "wouter";

import { useNavigationStore } from "stores/navigationStore";
import { generateTailwindGradientClassNames } from "utilities/colorUtilities";
import { getHrefAttributeForLinkToRecord } from "utilities/navigationUtilities";

export default function FurtherReadingLinks() {
  const { selectedArticle, selectedSection, allSectionsAndArticles } =
    useNavigationStore();

  if (selectedArticle.furtherReadingLinks.length) {
    return (
      <>
        <h3 id="further-reading" className="text-2xl font-bold mb-6">
          Further reading
        </h3>

        <div class="grid md:grid-cols-2 gap-4">
          {selectedArticle.furtherReadingLinks.map((resource) => {
            let href = getHrefAttributeForLinkToRecord(
              resource.slug,
              selectedSection,
              allSectionsAndArticles
            );
            return (
              <Link
                to={href}
                className={clsx(
                  generateTailwindGradientClassNames(resource.id),
                  "rounded-lg p-6 shadow hover:opacity-80 transition ease-in-out"
                )}
              >
                <h3 className="text-xl font-bold my-0">{resource.title}</h3>

                <p>{resource.description}</p>
              </Link>
            );
          })}
        </div>
      </>
    );
  } else {
    return null;
  }
}
