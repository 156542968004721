import { forwardRef } from "react";
import { Link } from "wouter";
import clsx from "clsx";
import { motion, useScroll, useTransform } from "framer-motion";

import Logo from "images/logo.jpg";

import { Search } from "./Search";

import {
  MobileNavigation,
  useIsInsideMobileNavigation,
} from "components/MobileNavigation";
import { useMobileNavigationStore } from "components/MobileNavigation";

const Header = forwardRef(function Header({ className }, ref) {
  let { isOpen: mobileNavIsOpen } = useMobileNavigationStore();
  let isInsideMobileNavigation = useIsInsideMobileNavigation();

  let { scrollY } = useScroll();
  let bgOpacityLight = useTransform(scrollY, [0, 72], [0.5, 0.9]);
  let bgOpacityDark = useTransform(scrollY, [0, 72], [0.2, 0.8]);

  return (
    <motion.div
      ref={ref}
      className={clsx(
        className,
        "lg:border-b lg:border-slate-900/10",
        "fixed inset-x-0 top-0 z-50 flex h-14 items-center justify-between gap-12 px-4 transition sm:px-6 lg:left-72 lg:z-30 lg:px-8 xl:left-80",
        !isInsideMobileNavigation &&
          "backdrop-blur-sm dark:backdrop-blur lg:left-72 xl:left-80",
        isInsideMobileNavigation
          ? "bg-white dark:bg-slate-900"
          : "bg-white/[var(--bg-opacity-light)] dark:bg-slate-900/[var(--bg-opacity-dark)]"
      )}
      style={{
        "--bg-opacity-light": bgOpacityLight,
        "--bg-opacity-dark": bgOpacityDark,
      }}
    >
      <div
        className={clsx(
          "absolute inset-x-0 top-full h-px transition",
          (isInsideMobileNavigation || !mobileNavIsOpen) &&
            "bg-zinc-900/7.5 dark:bg-white/7.5"
        )}
      />

      <div className="flex items-center gap-5 lg:hidden">
        <MobileNavigation />

        <Link to="/" aria-label="Home">
          <img src={Logo} alt="Carebit logo" style={{ width: "120px" }} />
        </Link>
      </div>

      <div className="flex items-center gap-5">
        <nav className="block">
          <ul className="flex items-center gap-8">
            <li className="text-xl text-slate-700 font-bold mr-20 dark:text-white">
              Support
            </li>
          </ul>
        </nav>
      </div>

      <div className="">
        <Search />
      </div>
    </motion.div>
  );
});

export default Header;
