import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useNavigationStore } from "stores/navigationStore";

const PageTableOfContents = () => {
  const { selectedArticle } = useNavigationStore();

  const [tableOfContents, setTableOfContents] = useState([]);
  const [headingCurrentlyInView, setHeadingCurrentlyInView] = useState(null);

  useEffect(() => {
    if (window.location.hash?.length && tableOfContents.length) {
      // On initial render, scroll to the element with the same ID as the hash in the URL (if there is a hash)
      document
        .getElementById(window.location.hash.replace("#", ""))
        ?.scrollIntoView();
    }
  }, [tableOfContents.length]);

  useEffect(() => {
    if (tableOfContents.length === 0) {
      return;
    }

    const onScroll = () => {
      let windowScrollY = window.scrollY;
      let current = tableOfContents[0];

      for (let item of tableOfContents) {
        if (windowScrollY >= item.scrollYPosition) {
          current = item;
        } else {
          break;
        }
      }
      setHeadingCurrentlyInView(current);
    };
    window.addEventListener("scroll", onScroll, { passive: true });
    onScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [tableOfContents]);

  useEffect(() => {
    let initialTableOfContents = Array.from(
      document.querySelectorAll(".Article-body h2, .Article-body h3")
    ).map((node) => {
      let scrollYPosition =
        window.scrollY + node.getBoundingClientRect().top - 80; // 80 is approximately the header height

      return {
        title: node.innerText,
        id: node.children[0].hash,
        scrollYPosition,
      };
    });

    setTableOfContents(initialTableOfContents);
    setHeadingCurrentlyInView(initialTableOfContents[0]);
  }, [selectedArticle]);

  if (tableOfContents.length) {
    return (
      <div className="">
        <h2
          id="on-this-page-title"
          className="font-display text-md font-bold text-slate-700 dark:text-white"
        >
          On this page
        </h2>

        <ol className="mt-2 space-y-1 text-md">
          {tableOfContents.map((item) => {
            let isActive = headingCurrentlyInView.title === item.title;

            return (
              <li key={item.title}>
                <h3>
                  <a
                    href={item.id}
                    className={clsx(
                      "flex",
                      isActive
                        ? "font-bold text-sky-500"
                        : "font-medium text-slate-500"
                    )}
                  >
                    {item.title}
                  </a>
                </h3>
              </li>
            );
          })}
        </ol>
      </div>
    );
  } else {
    return null;
  }
};

export default PageTableOfContents;
