import { create } from "zustand";

import {
  generateNavigation,
  getSelectedResources,
  flattenNavigation,
  getLastSlugFromPath,
} from "utilities/navigationUtilities";

export const useNavigationStore = create((set) => ({
  navigation: [],
  allSectionsAndArticles: [],
  showDrafts: false,
  setShowDrafts: (value) => {
    return set(() => ({
      showDrafts: value,
    }));
  },
  setNavigation: (cmsSections) => {
    let navigation = generateNavigation(cmsSections);
    let allSectionsAndArticles = flattenNavigation(navigation);

    let selectedSlug = getLastSlugFromPath(window.location.pathname);
    let { selectedSection, selectedArticle } = getSelectedResources(
      allSectionsAndArticles,
      selectedSlug
    );

    return set(() => ({
      navigation,
      allSectionsAndArticles,
      selectedArticle,
      selectedSection,
      selectedSlug,
    }));
  },

  selectedSection: null,
  selectedArticle: null,
  selectedSlug: null,

  setSelectedSectionAndArticle: (changeObject) => {
    return set(() => ({
      selectedSection: changeObject.selectedSection,
      selectedArticle: changeObject.selectedArticle,
    }));
  },
}));
