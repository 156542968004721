import React, { useContext } from "react";
import ReactPlayer from "react-player";

import { useNavigationStore } from "stores/navigationStore";

import DatoCMSRichTextRenderer from "components/DatoCMSRichTextRenderer";
import Banner from "components/Banner";

import { AuthenticationContext } from "App";

const ArticleBody = () => {
  const { selectedArticle, selectedSection, allSectionsAndArticles } =
    useNavigationStore();

  const { authenticatedStaffMember } = useContext(AuthenticationContext);

  if (selectedArticle) {
    return (
      <div className="Article-body">
        {(selectedArticle?.content || []).map((contentItem) => {
          if (["video_block"].includes(contentItem._modelApiKey)) {
            return (
              <div key={contentItem.id} className="aspect-video mt-6 mb-6">
                <ReactPlayer
                  url={contentItem.video.url}
                  width="100%"
                  height="100%"
                  controls={true}
                />
                <p className="text-slate-500 italic">{contentItem.caption}</p>
              </div>
            );
          } else if (
            ["info_block", "warning_block"].includes(contentItem._modelApiKey)
          ) {
            return (
              <Banner
                key={contentItem.id}
                status={contentItem._modelApiKey.split("_")[0]}
                title={contentItem.title}
              >
                <DatoCMSRichTextRenderer
                  contentItem={contentItem}
                  selectedSection={selectedSection}
                  allSectionsAndArticles={allSectionsAndArticles}
                />
              </Banner>
            );
          } else if (
            ["internal_note_rich_text_block"].includes(contentItem._modelApiKey)
          ) {
            if (authenticatedStaffMember.email.includes("@carebit.co")) {
              return (
                <div
                  key={contentItem.id}
                  className="mt-8 shadow rounded py-2 px-4 text-sm bg-yellow-100"
                  style={{ fontFamily: "monospace" }}
                >
                  <p className="text-md font-bold mt-0">
                    Internal note (viewable only by Carebit staff)
                  </p>

                  <DatoCMSRichTextRenderer
                    contentItem={contentItem}
                    selectedSection={selectedSection}
                    allSectionsAndArticles={allSectionsAndArticles}
                  />
                </div>
              );
            } else {
              return null;
            }
          } else if (contentItem.richText) {
            return (
              <DatoCMSRichTextRenderer
                key={contentItem.id}
                contentItem={contentItem}
                selectedSection={selectedSection}
                allSectionsAndArticles={allSectionsAndArticles}
              />
            );
          } else if (contentItem.image) {
            return (
              <div className="my-6" key={contentItem.id}>
                <img
                  className="p-2 mb-2 rounded shadow-md"
                  src={contentItem.image.url}
                  alt={contentItem.caption}
                />
                <p className="text-slate-500 italic mt-0 mb-6">
                  {contentItem.caption}
                </p>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default ArticleBody;
