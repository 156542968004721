import Cookies from "universal-cookie";

export const mockAuthenticatedStaffMember = {
  id: "63bf738a-22bd-4d47-98bf-4a5b5c7580de",
  email: "example.staff-member@carebit.co",
  first_name: "Sharon",
  last_name: "Staff-Member",
  formatted_name: "Sharon Staff-Member",
  organization: {
    id: "63bf738a-22bd-4d47-98bf-4a5b5c7580de",
    name: "Santis",
    medical_specialty: "urology",
    admin_url: "https://santis.carebit.co/admin",
  },
};

export const AUTHENTICATION_COOKIE_NAME = "carebitAuthenticatedStaffMember";

export const setMockAuthenticationCookie = () => {
  const cookies = new Cookies();

  let maxAge = 60 * 60 * 24 * 7; // 1 week in seconds

  // Note that HttpOnly is deliberately not set here because we need to read the cookie in the support.carebit.co client!
  let cookieChunks = [
    `${AUTHENTICATION_COOKIE_NAME}=${JSON.stringify(
      mockAuthenticatedStaffMember
    )}`,
    `path=/`,
    // `domain=support.carebit.co`, // Comment this out to use the current domain
    `secure`,
    `SameSite=None`,
    `Max-Age=${maxAge}`,
  ];

  cookies.set(cookieChunks.join(";"));
};
