// A Page contains all the content displayed to the user for the currently selected slug.
// It tries to find and display an Article from the CMS that matches the slug.
//
// Additionally, this component will try and find a Section from the CMS that matches the slug and display any subsections
// for that Section as well.

import React, { useContext } from "react";

import { useNavigationStore } from "stores/navigationStore";

import PageTableOfContents from "components/PageTableOfContents";
import ArticleBody from "components/ArticleBody";
import SectionResources from "components/SectionResources";
import FurtherReadingLinks from "components/FurtherReadingLinks";

import { AuthenticationContext } from "App";

const ArticleWrapper = () => {
  const { selectedArticle } = useNavigationStore();

  const { authenticatedStaffMember } = useContext(AuthenticationContext);

  return (
    <>
      <div className="flex relative">
        <div className="Article !w-full !xl:w-2/3 !max-w-full prose dark:prose-invert">
          {selectedArticle ? (
            <h1 className="text-3xl mb-2 font-bold">
              {selectedArticle.title}
              {selectedArticle.status === "draft" && " [Draft]"}
              {authenticatedStaffMember &&
                authenticatedStaffMember.email.includes("@carebit.co") &&
                selectedArticle && (
                  <a
                    href={`https://carebit-admin-support.admin.datocms.com/editor/item_types/1653239/items/${selectedArticle.id}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="ml-3 relative -top-1 text-sm text-sky-500"
                  >
                    [edit]
                  </a>
                )}
            </h1>
          ) : null}

          <ArticleBody />

          {selectedArticle && <FurtherReadingLinks />}
        </div>

        {/* hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6 */}

        <div className="mt-4 hidden xl:block xl:w-1/3 text-md font-bold pl-20 xl:sticky xl:top-[4.5rem] self-start">
          <PageTableOfContents />
        </div>
      </div>

      <div className="mt-4">
        <SectionResources />
      </div>
    </>
  );
};

export default ArticleWrapper;
